<template>
  <div v-if="isPermission">
      <select-shop  id="transferReportSelectShop" :loadingButton="loadingButton" v-on:getData="getStock"></select-shop>
      <CCard>
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-9 d-inline-flex align-items-center">
              <h2 class="font-weight-normal">
                {{ $t("transferReportTXT") }}
              </h2>
              <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div class="col-md-2 col-sm-3 col-4 text-right text-success">
              <CButton v-if="isExport" v-on:click="exportProductList()" block color="info" class="mb-1">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date style="margin-bottom: -10px;"></show-date>
              <CRow>
                 <h5 class="text-dark mt-2 ml-2">{{ $t('transferType') }} :</h5>
                <CCol class="col-lg-3 col-md-3 col-sm-6 col-7">
                  <select id="transferReportTypeSelect" class="custom-select" v-model="typeDoc" @change="getStock(1)">
                    <option id="transferReportAllDocOption" value="" selected>{{ $t("allDoc") }}</option>
                    <option id="transferReportTransferOutOption" value="3">{{ $t("transferOut") }}</option>
                    <option id="transferReportTransferInOption" value="4">{{ $t("transferIn") }}</option>
                  </select>
                </CCol>
              </CRow>
              <hr />
              <CDataTable
                id="transferReportTable"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
                clickableRows
                style="width: 100%; white-space: nowrap"
              >
              <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8">
                      {{ $t("noItem") }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination
            :meta_data="meta_data"
            v-on:next="getStock"
          >
          </pagination>
        </CCardBody>
      </CCard>
  </div>
  <div v-else>
      <access-data></access-data>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "@/services/service";
import Pagination from "../../../containers/Pagination";
import { CButton } from "@coreui/vue";
import report from '@/services/report'
import permis from '@/util/permission'
import HelpButton from "../../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      datastatus: "",
      loading: false,
      transferIn: false,
      transferOut: false,
      typeDoc: "",
      searchLoadingButton: true,
      keyword: "",
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    uid() {
      const uid = localStorage.getItem('shopsUid')
      if (!uid) {
        return this.shop ? this.shop.uid : null
      }
      return uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
      
    },
    shop(){
      return this.shops.find((i) => i.objectId === this.shopObjectId);
      
    },
    startDate() {
      return moment(String(this.date.start)).format("DD MMMM YYYY");
    },
    endDate() {
      return moment(String(this.date.end)).format("DD MMMM YYYY");
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    fields() {
      return [
        {
          key: "created_at",
          label: this.$i18n.t("date"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "stockDocType",
          label: this.$i18n.t("adjustDocType"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "shopName",
          label: this.$i18n.t("sourceBranch"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "siteName",
          label: this.$i18n.t("destinationShopBranch"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "PLUCode",
          label: this.$i18n.t("pluCode"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "name",
          label: this.$i18n.t("productName"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "quantity",
          label: this.$i18n.t("quantity"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "quantityReceived",
          label: this.$i18n.t("quantityReceived"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },

        {
          key: "stockNo",
          label: this.$i18n.t("documentNo"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "contractDocumentstockNo",
          label: this.$i18n.t("refNo"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "received_at",
          label: this.$i18n.t("receivedAt"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
        {
          key: "dueDate",
          label: this.$i18n.t("dueDate"),
          _classes: "text-left width:5% text-dark font-weight-normal",
        },
      ];
    },
    items() {
      let data = this.data || [];
      let detail = [];

      for (let i = 0; i < data.length; i++) {
        let stockNo = data[i].stockNo == null ? "-" : data[i].stockNo;
        let refDoc;

        if (data[i].stockDocType === 3) {
          refDoc =
            data[i].contractDocument && data[i].contractDocument.stockNo
              ? data[i].contractDocument.stockNo
              : "-";
        } else if (data[i].stockDocType === 4) {
          refDoc =
            data[i].refDocument && data[i].refDocument.stockNo
              ? data[i].refDocument.stockNo
              : "-";
        } else {
          refDoc = "-";
        }

        let sourceBranch, siteBranch;

        if (data[i].stockDocType === 3) {
          sourceBranch = data[i].shop == null ? "-" : data[i].shop.branchName;
          siteBranch = data[i].site == null ? "-" : data[i].site.branchName;
        } else if (data[i].stockDocType === 4) {
          sourceBranch = data[i].source == null ? "-" : data[i].source.branchName;
          siteBranch = data[i].shop == null ? "-" : data[i].shop.branchName;
        } else {
          sourceBranch = "-";
          siteBranch = "-";
        }

        let dueDate = data[i].dueDate
          ? moment(String(data[i].dueDate)).format("DD/MM/YYYY")
          : "-";
        let receivedAt = data[i].received_at
          ? moment(String(data[i].received_at)).format("DD/MM/YYYY")
          : "-";
        let stockDocTypeText =
          data[i].stockDocType === 3
            ? this.$t("transferOut")
            : data[i].stockDocType === 4
            ? this.$t("transferIn")
            : "-";

        let items = Array.isArray(data[i].items) ? data[i].items : [data[i].items];

        for (let j = 0; j < items.length; j++) {
          let item = items[j];
          let PLUCode =
            item.productPLU && item.productPLU.PLUCode ? item.productPLU.PLUCode : "-";
          let name = item.productPLU && item.productPLU.name ? item.productPLU.name : "-";
          let quantity = item.quantity != null ? item.quantity : 0;
          let quantityReceived =
            item.quantityReceived != null ? item.quantityReceived : 0;

          detail.push({
            created_at: moment(String(data[i].created_at))
              .tz("Asia/Bangkok")
              .format("DD/MM/YYYY HH:mm"),
            stockDocType: stockDocTypeText,
            shopName: sourceBranch,
            siteName: siteBranch,
            PLUCode: PLUCode,
            name: name,
            quantity: quantity,
            quantityReceived: quantityReceived,
            stockNo: stockNo,
            contractDocumentstockNo: refDoc,
            received_at: receivedAt,
            dueDate: dueDate,
          });
        }
      }
      return detail;
    },
  },
  created() {
    this.getStock(1);
  },
  methods: {
    getStock(page = 1) {
      this.loadingButton = false;
      let uid = localStorage.getItem('shopsUid');
      if (!uid) {
        uid = this.shop ? this.shop.uid : null;
      }
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      let params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      };

      if (this.typeDoc != "") {
        params = {
          ...params,
          type: this.typeDoc,
        };
      }
      const headers = {
        shopObjectId: shopObjectId,
      };
      this.loading = true;
      axios({
        url: `/api/v1.0/${uid}/document/tranfer/product`,
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          this.data = res.data.data;
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0;
          } else {
            this.meta_data.items = res.data.paginate.items;
          }
          this.meta_data.last_page = res.data.paginate.pageCount;
          this.meta_data.current_page = res.data.paginate.currentPage;
          this.meta_data.itemCount = res.data.paginate.itemCount;
          this.meta_data.limit = res.data.paginate.perPage;
          // this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
          this.loading = false;
          this.loadingButton = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportProductList() {
      let uid = localStorage.getItem('shopsUid');
      if (!uid) {
        uid = this.shop ? this.shop.uid : null;
      }
      const shopObjectId = this.shopObjectId;

      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

      let params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      if (this.typeDoc != "") {
        params = {
          ...params,
          type: this.typeDoc,
        };
      }
      const headers = {
        shopObjectId: shopObjectId,
      };
      report({
        url: "/api/v1.0/" + uid + "/document/tranfer/product/excel",
        params: params,
        method: "GET",
        headers: headers,
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "รายงานรายละเอียดการส่งหรือรับสินค้า.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style src="../../../assets/styles/inventory.css" scoped></style>
<style scoped>
@media (min-width: 1300px) {
  .adjust-xl-margin {
    margin-right: -50px !important;
  }
}
</style>
